$(function () {

    // var fetch_events = {
    //
    //     events_div: $('#events'),
    //     older_btn: $('#load-older-events'),
    //     newer_btn: $('#load-newer-events'),
    //
    //     older_spinner: $('#load-older-spinner'),
    //     newer_spinner: $('#load-newer-spinner'),
    //
    //     latest_first_fetch: undefined,
    //     latest_last_fetch: undefined,
    //
    //     first_events: undefined,
    //     latest_events: undefined,
    //
    //     group_slug: undefined,
    //
    //     init: function (fetch) {
    //         if (fetch.status === 200) {
    //             this.latest_first_fetch = fetch;
    //             this.latest_last_fetch = fetch;
    //             this.first_events = fetch.events;
    //             this.latest_events = fetch.events;
    //         } else {
    //             // Something went wrong when fetching
    //         }
    //     },
    //
    //     update: function (old_or_new, fetch) {
    //         if (fetch.status === 200) {
    //             switch (old_or_new) {
    //                 case 'old':
    //                     this.latest_first_fetch = fetch;
    //                     this.first_events = fetch.events;
    //                     break;
    //                 case 'new':
    //                     this.latest_last_fetch = fetch;
    //                     this.latest_events = fetch.events;
    //                     break;
    //                 default:
    //                     break;
    //             }
    //         } else {
    //             // Something went wrong when fetching
    //         }
    //     },
    //
    //     fetch: function (old_or_new, date, spinner, callback) {
    //         var url = '/group' + '/' + this.group_slug + '/' + old_or_new + '/' + date;
    //         $.ajax({
    //             type: "GET",
    //             url: url,
    //             dataType: 'json',
    //             beforeSend: function () {
    //                 spinner.fadeIn(100);
    //             },
    //             complete: function(){
    //                 spinner.fadeOut(100);
    //             },
    //             success: function (data) {
    //                 parent.update(data);
    //                 if (data.status === 200) callback();
    //             },
    //             error: function (data) {
    //                 console.log(data);
    //             }
    //         });
    //     },
    //
    //     older: function () {
    //         if (this.first_events.length < this.latest_first_fetch.fetch_limit) {
    //             this.older_btn.remove();
    //         } else {
    //             var date = new Date(this.first_events[0]);
    //             this.fetch('old', date.getFullYear()+'-'+date.getMonth()+'-'+date.getDay(), this.older_spinner, function (){
    //                 // Callback after finish fetching
    //                 if (this.first_events.length < this.latest_first_fetch.fetch_limit) {
    //                     this.older_btn.remove();
    //                 } else {
    //                     this.prepend(this.first_events);
    //                 }
    //             });
    //         }
    //     },
    //
    //     newer: function () {
    //         if (this.latest_events.length < this.latest_last_fetch.fetch_limit) {
    //             this.newer_btn.remove();
    //         } else {
    //             var date = new Date(this.latest_events[0]);
    //             this.fetch('new', date.getFullYear()+'-'+date.getMonth()+'-'+date.getDay(), this.newer_spinner, function (){
    //                 // Callback after finish fetching
    //                 if (this.latest_events.length < this.latest_last_fetch.fetch_limit) {
    //                     this.newer_btn.remove();
    //                 } else {
    //                     this.append(this.latest_events);
    //                 }
    //             });
    //         }
    //     },
    //
    //     createElems: function (events) {
    //
    //     },
    //
    //     prepend: function (events) {
    //         var elems = this.createElems(events);
    //     },
    //
    //     append: function (events) {
    //         var elems = this.createElems(events);
    //     }
    //
    // };


    $('.deleteEventForm').submit(function () {
        return confirm("Weet u het zeker? Dit kan niet ongedaan worden.");
    });

    $('[data-toggle="tooltip"]').tooltip();

    // var firstPanel = $('.panel-collapse').first();
    // $(firstPanel).collapse();
    // var id = $(firstPanel).attr('id');
    // $('#chevron-' + id).find('i').removeClass('fa-chevron-right');
    // $('#chevron-' + id).find('i').addClass('fa-chevron-down');

    // $('.presence-notifier').click(function (e){
    //     e.preventDefault();
    //     var url = $(this).data('presence');
    //     var newClass = "bg-presence-";
    //     $.ajax({
    //         type: "GET",
    //         url: url,
    //         dataType: 'json',
    //         beforeSend: function(){
    //             $('[data-presence="'+ url +'"]').each(function (){
    //                 $(this).parent().parent().children('li').each(function () {
    //                     $(this).addClass('disabled');
    //                 });
    //             });
    //         },
    //         complete: function(){
    //             $('[data-presence="'+ url +'"]').each(function (){
    //                 $(this).parent().parent().children('li').each(function () {
    //                     $(this).removeClass('disabled');
    //                 });
    //             });
    //         },
    //         success: function (data) {
    //             if (data.status === 200) {
    //                 $('[data-presence="'+ url +'"]').each(function (){
    //                     $(this).parent().parent().children('li').each(function () {
    //                         $(this).removeClass(newClass + data.previous_presence);
    //                         $(this).addClass(newClass + data.presence);
    //                     });
    //                 });
    //                 var elem = $('#event-' + data.event.id).parent().find('.panel-footer').find('.event-amount-label');
    //                 $(elem).text(data.event.amount_present);
    //                 $(elem).removeAttr('class');
    //                 $(elem).addClass('event-amount-label');
    //                 $(elem).addClass('label');
    //                 $(elem).addClass('label-' + data.event.amount_label);
    //             }
    //         },
    //         error: function (data) {
    //             console.log('Error:', data);
    //         }
    //     });
    // });

    // fetch_events.init(fetch);

    // fetch_events.older_btn.click(function () {
    //    fetch_events.older();
    // });

    // fetch_events.newer_btn.click(function () {
    //    fetch_events.newer();
    // });

});

// function updateChevron(id) {
//     if ($('#event-' + id).hasClass('in')) {
//         $('#chevron-event-' + id).find('i').removeClass('fa-chevron-down');
//         $('#chevron-event-' + id).find('i').addClass('fa-chevron-right');
//     } else {
//         $('#chevron-event-' + id).find('i').removeClass('fa-chevron-right');
//         $('#chevron-event-' + id).find('i').addClass('fa-chevron-down');
//     }
// }
